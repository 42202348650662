import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, Routes } from 'react-router-dom';



import { ROUTES } from 'shared/constants';



import AuthRoute from './AuthRoute';
import DevRoute from './DevRoute';
import EmbeddingLayout from './EmbeddingLayout';
import FreeRoute from './FreeRoute';
import Layout from './Layout';
import PrivateRoute from './PrivateRoute';


const Login = lazy(() => import('pages/auth/login'));
const Social = lazy(() => import('pages/auth/social'));
const ForgotPassword = lazy(() => import('pages/auth/forgotPassword'));
const CreateNewPassword = lazy(() => import('pages/auth/createNewPassword'));
const Home = lazy(() => import('pages/home'));
const PublicVideo = lazy(() => import('pages/public-video'));
const Channel = lazy(() => import('pages/channel'));
const Search = lazy(() => import('pages/search'));
const MyAccount = lazy(() => import('pages/account-settings'));
const ListUsersVideo = lazy(() => import('pages/user-videos/list-users-video'));
const UserVideoDetail = lazy(() => import('pages/user-videos/user-video-detail'));
const Info = lazy(() => import('pages/info/Info'));
const About = lazy(() => import('pages/info/About'));
const Contact = lazy(() => import('pages/info/Contact'));
const CookiePolicy = lazy(() => import('pages/info/CookiePolicy'));
const PrivacyPolicy = lazy(() => import('pages/info/PrivacyPolicy'));
const UserAgreement = lazy(() => import('pages/info/UserAgreement'));
const UpgradeAccount = lazy(() => import('pages/info/UpgradeAccount'));
const RequestDeleteData = lazy(() => import('pages/info/RequestDeleteData'));
const PublicVideoIframe = lazy(() => import('pages/public-video-iframe'));
const Onboarding = lazy(() => import('features/embedding/auth/Onboarding.tsx'));
const CoCreatorInvite = lazy(() => import('pages/co-creator-invite'));
const DevSettings = lazy(() => import('pages/dev-settings'));

const Router = () => {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route element={<EmbeddingLayout />}>
          <Route element={<FreeRoute />}>
            <Route path={ROUTES.EMBED_VIDEO} element={<PublicVideoIframe />} />
          </Route>
        </Route>
        <Route element={<Layout />}>
          <Route element={<FreeRoute />}>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.SEARCH} element={<Search />} />
            <Route path={ROUTES.PUBLIC_VIDEO_DETAIL} element={<PublicVideo />} />
            <Route path={ROUTES.INFO.INFO} element={<Info />} />
            <Route path={ROUTES.INFO.ABOUT} element={<About />} />
            <Route path={ROUTES.INFO.CONTACT} element={<Contact />} />
            <Route path={`${ROUTES.CHANNEL}:channel_id`} element={<Channel />} />
            <Route path={ROUTES.INFO.COOKIE_POLICY} element={<CookiePolicy />} />
            <Route path={ROUTES.INFO.USER_AGREEMENT} element={<UserAgreement />} />
            <Route path={ROUTES.INFO.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={ROUTES.INFO.UPGRADE_ACCOUNT} element={<UpgradeAccount />} />
            <Route path={ROUTES.INFO.REQUEST_DELETE_DATA} element={<RequestDeleteData />} />
            <Route path={ROUTES.ONBOARDING} element={<Onboarding />} />
            <Route path={ROUTES.CO_CREATOR_INVITE} element={<CoCreatorInvite />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route path={ROUTES.AUTH.LOGIN} element={<Login />} />
            <Route path={`${ROUTES.AUTH.LINKEDIN_REDIRECT}`} element={<Social />} />
            <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={`${ROUTES.AUTH.PASSWORD_RECOVERY}/:id`} element={<CreateNewPassword />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={ROUTES.MY_ACCOUNT} element={<MyAccount />} />
            <Route path={ROUTES.MY_DATA} element={<ListUsersVideo />} />
            <Route path={ROUTES.USER_VIDEO_DETAIL} element={<UserVideoDetail />} />
            {/*<Route path={`${ROUTES.ONBOARDING}/:step`} element={<Onboarding />} />*/}
            <Route path="/" element={<Navigate to={ROUTES.HOME} replace />} />
          </Route>
          <Route element={<DevRoute />}>
            <Route path={ROUTES.DEV_SETTINGS} element={<DevSettings />} />
          </Route>
          <Route path="*" element={<Home />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default Router;