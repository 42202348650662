export const ROUTES = {
  HOME: 'home',
  MY_ACCOUNT: 'my-account',
  DEV_SETTINGS: 'dev-settings',
  MY_DATA: 'my-data',
  USER_VIDEO_DETAIL: 'my-data/:video_id',
  PUBLIC_VIDEO_DETAIL: 'video/:video_id',
  EMBED_VIDEO: 'embed/video/:video_id',
  PUBLIC_VIDEO: 'video/',
  CHANNEL: 'channel/',
  SEARCH: 'search',
  ONBOARDING: 'onboarding',
  CO_CREATOR_INVITE: 'co-creator-invites/:invite_code',
  AUTH: {
    CONFIRM: '/confirm',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    PASSWORD_RECOVERY: '/password-recovery',
    LINKEDIN_REDIRECT: '/social/callback/:token',
  },
  INFO: {
    ABOUT: '/about',
    CONTACT: '/contact',
    UPGRADE_ACCOUNT: '/upgrade_account',
    INFO: '/info',
    USER_AGREEMENT: '/user_agreement',
    PRIVACY_POLICY: '/privacy_policy',
    COOKIE_POLICY: '/cookie_policy',
    REQUEST_DELETE_DATA: '/request_delete_data',
  },
} as const;
