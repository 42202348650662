import { memo, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'shared/constants';
import useAuthStore from 'shared/store/auth.slice';
import { PageLoader } from 'entities/components';

const DevRoute = () => {
  const token = useAuthStore((state) => state.credentials?.token);
  const roles = useAuthStore((state) => state.userProfile?.roles)
  if (!token || !roles?.includes('dev'))
    return <Navigate to={ROUTES.HOME} />;

  return (
    <Suspense fallback={<PageLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default memo(DevRoute);
